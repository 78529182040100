import React from 'react';
import { graphql } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Layout from '../../Layout';
import styled from './BlogPost.module.css';
import { getClaudImage } from '../../cloudinaryUtils';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  const images = [];
  post.frontmatter.image != undefined && images.push(post.frontmatter.image);
  post.frontmatter.image2 != undefined && images.push(post.frontmatter.image2);
  post.frontmatter.image3 != undefined && images.push(post.frontmatter.image3);
  post.frontmatter.image4 != undefined && images.push(post.frontmatter.image4);
  return (
    <Layout>
      <div className={styled.articleWrapper}>
        <AliceCarousel autoPlay autoPlayInterval="3000">
          {images.map((image) => {
            // getCloudImage extracts the image name and a '/' from the image URL
            const img = getClaudImage(image);
            return (
              <img
                className={styled.imageBlock}
                srcset={`https://res.cloudinary.com/gorlipark/image/upload/w_320/v1615886223/Woodworking%20Wednesday/${img} 640w,
                 https://res.cloudinary.com/gorlipark/image/upload/w_384/v1615886223/Woodworking%20Wednesday/${img} 768w,
                 https://res.cloudinary.com/gorlipark/image/upload/w_512/v1615886223/Woodworking%20Wednesday/${img} 1024w,
                 https://res.cloudinary.com/gorlipark/image/upload/w_800/v1615886223/Woodworking%20Wednesday/${img} 1600w,
                 https://res.cloudinary.com/gorlipark/image/upload/w_960/v1615886223/Woodworking%20Wednesday/${img} 1920w`}
                src={image}
                alt={post.frontmatter.title}
              />
            );
          })}
        </AliceCarousel>

        <div className={styled.bodyWrapper}>
          <div className={styled.headerBlock}>
            <span className={styled.headerTitle}>{post.frontmatter.title}</span>
            <span className={styled.headerDate}>{post.frontmatter.date}</span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        image
      }
      fields {
        slug
      }
    }
  }
`;
